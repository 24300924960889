import React, { useState } from "react";
import AddGroupModal from "../GroupsModals/AddGroupModal";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@material-ui/core";
import ButtonLoader from "../ButtonLoader";
import * as DATANETAPI from "../DataNet/DataNet";
import { useTranslation } from "react-i18next";
import { orange } from "@material-ui/core/colors";

const ADD_NEW_GROUP = "AddGroup";

const GroupNotCompatibleModal = ({ open, setOpen, cancel, deviceComs, setNewGroupId, nextStep, programId, user }) => {
  const { t } = useTranslation();

  const [isCreateGroupOpend, setIsCreateGroupOpend] = useState(false);
  const [isGroupCreated, setIsGroupCreated] = useState(false);
  const [temporalGroup, setTemporalGroup] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const cancelAdd = () => {
    setOpen(false);
    cancel();
  };

  const closeGroupModal = (newGroupID) => {
    console.log("datos del grupo creado", newGroupID);
    /* Si viene id, es que se ha creado un nuevo grupo, sino el grupo no se ha creado */
    if (!!newGroupID) {
      console.log("mando la info al padre", newGroupID);
      setNewGroupId(newGroupID);
      setIsGroupCreated(true);
      setTemporalGroup(newGroupID);
    }
    setIsCreateGroupOpend(false);
  };

  const next = () => {
    console.log("next");
    setIsLoading(true);
    DATANETAPI.getGroup(temporalGroup).then((snap) => {
      console.log("sanp del next", snap);
      if (!!snap?.data?.comsTypes) {
        nextStep();
        setOpen(false);
        setTemporalGroup("");
        setIsGroupCreated(false);
        setIsLoading(false);
      } else {
        setTimeout(() => {
          next();
        }, 500);
      }
    });
  };

  const handleSelect = (e) => {
    setTemporalGroup(e.target.value);
  };

  const handleAccept = () => {
    if (temporalGroup !== "") {
      if (temporalGroup === ADD_NEW_GROUP) {
        setIsCreateGroupOpend(true);
      } else {
        closeGroupModal(temporalGroup);
      }
    }
  };

  if (isGroupCreated) {
    return (
      <Dialog maxWidth={"sm"} open={open}>
        <DialogTitle id="simple-dialog-title">Grupo asignado</DialogTitle>

        <DialogContent>
          <DialogContentText>
            <Typography>El grupo se ha asignado correctamente, ahora el equipo se añadira al nuevo grupo</Typography>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="secondary" disabled={isLoading} style={{ textTransform: "none" }} onClick={next}>
            Siguiente
            {isLoading && <ButtonLoader />}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  if (isCreateGroupOpend) {
    return (
      <Dialog maxWidth={"md"} fullWidth open={open}>
        <DialogTitle id="simple-dialog-title">Crear nuevo grupo</DialogTitle>
        <DialogContent>
          <Grid container>
            <AddGroupModal handleClose={closeGroupModal} deviceComs={deviceComs} isForNewDevice />
          </Grid>
        </DialogContent>
      </Dialog>
    );
  } else {
    return (
      <Dialog maxWidth={"sm"} open={open}>
        <DialogTitle id="simple-dialog-title">Grupo no compatible</DialogTitle>

        <DialogContent>
          <DialogContentText>
            <Typography>
              El grupo tiene una configuración de comunicaciones incompatible con el equipo. Para poder añadir el equipo, debes asignar un grupo que disponga de {deviceComs?.map((v) => v)}.
            </Typography>
            <Typography style={{ marginTop: 9 }}>Seleccione un grupo compatible o cree uno nuevo</Typography>
            <FormControl variant="outlined" fullWidth style={{ marginTop: 9 }}>
              <InputLabel id="demo-simple-select-outlined-label">Grupos compatibles</InputLabel>
              <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" value={temporalGroup} onChange={handleSelect} label="Grupos comptabiles">
                <MenuItem value={ADD_NEW_GROUP}>
                  <em>Nuevo grupo</em>
                </MenuItem>
                {programId != "" &&
                  user?.programas
                    ?.filter((v) => v.id === programId)[0]
                    ?.groups?.filter((g) => g?.comsTypes?.every((coms) => deviceComs?.includes(coms)))
                    .map((g, i) => <MenuItem value={g.id}>{g.name}</MenuItem>)}
              </Select>
            </FormControl>
            <Typography variant="caption" style={{ color: orange[300], fontWeight: 800 }}>
              {temporalGroup !== "" && temporalGroup !== ADD_NEW_GROUP && t("groupMove.settings.text")}
            </Typography>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="secondary" style={{ textTransform: "none" }} onClick={cancelAdd}>
            No añadir el equipo
          </Button>
          <Button variant="contained" color="primary" style={{ textTransform: "none" }} disabled={temporalGroup == ""} onClick={handleAccept}>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
};

export default GroupNotCompatibleModal;
