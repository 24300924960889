import React, { useEffect, useRef, useState } from "react";
import {
  makeStyles,
  Container,
  Grid,
  Dialog,
  AppBar,
  Toolbar,
  Slide,
  Button,
  Typography,
  IconButton,
  Step,
  Stepper,
  StepLabel,
  DialogActions,
  DialogContentText,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import ModelSelect from "./ModelSelect";
import DeviceForm from "./DeviceForm";
import { useTranslation } from "react-i18next";
import { red } from "@material-ui/core/colors";
import Confirmation from "./Confirmation";
import * as DATANETAPI from "../DataNet/DataNet";
import { useParams } from "react-router-dom";
import ErrorDialog from "../ErrorDialog";
import LoadingPage from "../LoadingPage";
import { R100ConfigChangedModal } from "../R100ConfigChangedModal";
import ButtonLoader from "../ButtonLoader";
import { useHistory } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import GroupNotCompatibleModal from "./GroupNotCompatibleModal";
import getDeviceComunications from "../../functions/getDeviceComunications";
import * as COMS from "../../constants/comsTypes";

const AddDevice = ({ open, handleClose, authUser, selectedGroupID }) => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const topRef = useRef(null);

  const [activeStep, setActiveStep] = useState(0);
  const [iscomplete, setIscomplete] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openAlertProccess, setOpenAlertProccess] = useState(false);
  const [devicesModels, setdevicesModels] = useState(devicesModelsBack);
  const [isFormValid, setisFormValid] = useState(false);
  const [formValues, setFormValues] = useState({ model: devicesModelsBack[0], manufacturer: "Bexen Cardio" });
  const [device, setdevice] = useState([]);
  const [group, setGroup] = useState(selectedGroupID ?? null);
  const [currentposition, setcurrentposition] = useState(DEFAULT_GPS_POSITION);
  const [isFinish, setIsFinish] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorIdLimbo, setErrorIdLimbo] = useState(-1);
  const [errorIdMove, setErrorIdMove] = useState(-1);
  const [errorIdCreate, setErrorIdCreate] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [firstInteraction, setFirstInteraction] = useState(true);
  const [groupName, setGroupName] = useState("");
  const [programId, setProgramId] = useState("");
  const [model, setModel] = useState(0);
  const [connectionErrorMsg, setConnectionErrorMsg] = useState("");
  const [openR100ConfingWarning, setOpenR100ConfingWarning] = useState(false);
  const [errorOnCreate, seterrorOnCreate] = useState(0);
  const [isGroupCompatible, setIsGroupCompatible] = useState(false);
  const [groupNotCompatibleModalOpen, setGroupNotCompatibleModalOpen] = useState(false);
  const [isNewGroupCreated, setIsNewGroupCreated] = useState(false);
  const steps = [t("devices.new.stepper.one"), t("devices.new.stepper.two"), t("devices.new.stepper.three")];

  const handleModelSelect = (value) => {
    if (value == "other") {
      setFormValues({ ...formValues, model: value, manufacturer: "" });
    } else {
      setFormValues({
        ...formValues,
        model: devicesModelsBack[value],
        manufacturer: "Bexen Cardio",
      });
    }
    setModel(value);
  };

  useEffect(() => {
    !!group &&
      DATANETAPI.getGroup(group).then((snap) => {
        console.log("respuesta getGroup", snap);
        if (snap.status < 0) {
          console.log("error en get group getGroup", group, snap);
          setConnectionErrorMsg(t(`error.connection_${snap.status}`));
        } else {
          setGroupName(snap?.data?.name);
          setProgramId(snap?.data?.programid);
          //setProgramId(authUser?.programas?.filter((p) => p.groups.some((g) => g.id == group))[0]?.id);
        }
      });
  }, [group]);

  const handleNext100 = () => {
    if (activeStep === 1) {
      //console.log("STEP 1 -> ", activeStep);
      setIsLoading(true);
      var R100 = {
        groupid: group,
        programid: programId,
        device: {
          name: formValues.name,
          serial: formValues.serial,
          model: formValues.model,
          groupName: groupName,
          programid: programId,
          manufacturer: formValues.manufacturer,
          code: formValues.code,
          location: {
            latitude: formValues.location.latitude,
            longitude: formValues.location.longitude,
            update_source: formValues.location.update_source,
            name: formValues.location.name,
            description: formValues.location.description,
          },
          openingTime: formValues.openingTime,
        },
      };

      DATANETAPI.getLimboDevice(R100).then(async (snap) => {
        topRef?.current?.scrollIntoView();
        if (snap.status < 0) {
          setConnectionErrorMsg(t(`error.connection_${snap.status}`));
        } else {
          if (snap != undefined) {
            //console.log("GET LIMBO STATUS ->", snap.status);
            setIsLoading(false);
            if (snap.status === 1) {
              //console.log("GET LIMBO DATA ->", snap.data);
              /* si no se añade, del limbro se trae el grupo 0000-00... */
              let device = snap.data;
              device["groupid"] = group;
              device.groupName = groupName;
              device.programid = programId;

              /**-------------------- TEST --------------------*/
              device.comsTypes = getDeviceComunications(device?.options);
              setdevice(device);
              /*
              Hay que cambiar el objeto por el que viene del usuario para que pueda coger los datos de las comunicaciones del grupo              
              */

              //se revisa si hay coincidencias en el grupo para determinar si son compatibles

              let groupComsTypes = authUser?.groups?.filter((g) => g.id === group)[0]?.comsTypes;
              /* puede ser que al crear el grupo durante el proceso de registro, no de tiempo a actualizar los datos del usuario
                por ese motivo, si no está el grupo, se hace una petición  para obtener los datos del grupo            
              */

              if (!!!groupComsTypes) {
                const groupRequestData = await DATANETAPI.getGroup(group);

                groupComsTypes = groupRequestData?.data?.comsTypes;
                device.programid = groupRequestData?.data?.programid;
                setdevice(device);
              }

              /* hay que revisar las coms del grupo por si hubiera algun error */

              if (device?.comsTypes.every((ct) => groupComsTypes?.includes(ct))) {
                //añadimos los parametros encesarios para poner los datos de fabrica (para evitar errores en el procesamiento de los datos)
                // ya que cuando se trae los datos del equipo, a no ser que se haya eliminado, no tiene los datos

                device.status.device_status = { updateSource: 0 };
                /* ------------------- */
                //setdevice(device);

                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                setIsFinish(true);
                setIsGroupCompatible(true);
                setGroupNotCompatibleModalOpen(false);
              } else {
                setGroupNotCompatibleModalOpen(true);
                setIsGroupCompatible(false);
                setIsFinish(true);
              }
              /**-------------------------------------------- */
            } else {
              //console.log("Error Getting 100 -> ");
              setOpenAlert(true);
              setIsError(true);
              setErrorIdLimbo(snap.status);
            }
          }
        }
      });
    } else if (activeStep === 2) {
      setIsLoading(true);
      /**
       * Es necesario confirmar que se quiere añadir al grupo, ya que se le va a cambiar
       * la configuración al sincronizar una vez añadido al nuevo grupo
       */
      if (!isNewGroupCreated) {
        DATANETAPI.getDevices(programId, group).then((snap) => {
          if (snap?.data?.length === 0) {
            finishR100Register(true);
          } else {
            setOpenR100ConfingWarning(true);
          }
        });
      } else {
        finishR100Register(true);
      }
    } else {
      //console.log("STEP ANYOTHER -> ", activeStep);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const finishR100Register = (finish) => {
    setOpenR100ConfingWarning(false);
    setIsFinish(false);
    if (finish) {
      var R100 = {
        groupid: group,
        programid: programId,
        device: {
          name: formValues.name,
          serial: formValues.serial,
          groupid: group,
          groupName: groupName,
          model: formValues.model,
          manufacturer: formValues.manufacturer,
          code: formValues.code,
          location: {
            latitude: formValues.location.latitude,
            longitude: formValues.location.longitude,
            update_source: formValues.location.update_source,
            name: formValues.location.name,
            description: formValues.location.description,
          },
          openingTime: formValues.openingTime,
        },
      };

      DATANETAPI.moveLimboDevice(R100).then((snap) => {
        //console.log("datos respuesta limbo 2--", snap);
        setIsLoading(false);
        if (snap.status < 0) {
          setConnectionErrorMsg(t(`error.connection_${snap.status}`));
        } else {
          if (snap != undefined) {
            if (snap.status === 1) {
              setOpenAlert(true);
              setIsError(false);
            } else {
              //console.log("Error Getting 100 -> ");
              setOpenAlert(true);
              setIsError(true);
              setErrorIdMove(snap.status);
            }
          }
        }
      });
    } else {
      history.push(ROUTES.PROGRAMS + "/" + programId + ROUTES.GROUPS);
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    topRef?.current?.scrollIntoView();
    if (activeStep === 0) {
      //console.log("STEP 0 -- NOT REANIBEX 100");
    } else if (activeStep === 1) {
      //console.log("STEP 1 -- NOT REANIBEX 100");
      setdevice({
        name: formValues.name,
        serial: formValues.serial,
        programid: programId,
        groupid: group,
        groupName: groupName,
        model: formValues.model,
        manufacturer: formValues.manufacturer,
        battery: {
          expiration_date: formValues.expirationDateBatt,
        },
        pads: {
          expiration_date: formValues.expirationDatePads,
        },
        location: {
          latitude: formValues.location.latitude,
          longitude: formValues.location.longitude,
          update_source: formValues.location.update_source,
          name: formValues.location.name,
          description: formValues.location.description,
        },
        openingTime: formValues.openingTime,
      });
      setIsFinish(true);
    } else if (activeStep === 2) {
      //console.log("STEP 2 -- NOT REANIBEX 100");
      if (isFormValid) {
        var deviceForm = {
          groupid: group,
          programid: programId,
          device: formValues,
        };
        DATANETAPI.createDevice(deviceForm).then((snap) => {
          if (snap.status < 0) {
            setConnectionErrorMsg(t(`error.connection_${snap.status}`));
          } else {
            if (snap != undefined) {
              setOpenAlert(true);
              if (snap?.status === 1) {
                setIsError(false);
              } else {
                /* revisar estado de error */
                seterrorOnCreate(snap?.status);
                setIsError(true);
                setActiveStep(1);
                setErrorIdCreate(snap?.status);
              }
            }
          }
        });
      } else {
        setIsError(true);
      }
    } else {
      setIsFinish(true);
    }
    if (activeStep + 1 === 3) {
      setOpenAlertProccess(false);
    }
  };

  const resetForm = () => {
    handleClose(false);
    setActiveStep(0);
    setIscomplete(false);
    setOpenAlert(false);
    setErrorIdLimbo(-1);
    setErrorIdMove(-1);
    setErrorIdCreate(-1);
    setdevicesModels(devicesModelsBack);
    setisFormValid(false);
    setFormValues({ model: devicesModelsBack[0], manufacturer: "Bexen Cardio" });
    setdevice({});
    setGroup(null);
    setIsFinish(false);
    setFirstInteraction(true);
    setModel(0);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setIsFinish(false); // cambia el estado del boton de finalizar a siguiente
    setFirstInteraction(false);
  };

  const closeXButton = (toClose) => {
    resetForm();
    handleClose(toClose);
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setcurrentposition({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  }, []);

  useEffect(() => {
    /**
     * cuando se actualizan los valores del formulario generar
     * el JSON device si el formulario es valido
     *
     */
    if (isFormValid) {
    }
  }, [isFormValid]);

  const setCreatedNewGroupId = (id) => {
    setGroup(id);
    setIsNewGroupCreated(true);
  };

  var isValid = false;

  isValid = activeStep === 0 && formValues.model !== "" && !!formValues.model && !!group ? true : false;
  isValid = activeStep === 1 && isFormValid ? true : isValid;
  isValid = activeStep === 2 ? true : isValid;

  return (
    <>
      <Dialog fullScreen open={open} TransitionComponent={Transition} id="newDeviceDialog">
        <AppBar className={classes.appBar} ref={topRef}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={() => closeXButton(false)} aria-label="close">
              <Close />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t("devices.new.title.add")}
            </Typography>
          </Toolbar>
        </AppBar>

        <div className={classes.root}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <Grid container justify="center" alignItems="center" style={{ height: "70vh" }}>
                <LoadingPage />
              </Grid>
            ) : (
              <Container>
                <Grid container spacing={4} justify="center">
                  <Grid item xs={12} container spacing={2} justify="center">
                    {activeStep === 0 && <ModelSelect models={devicesModels} model={model} setModel={handleModelSelect} group={group} setGroup={setGroup} programId={programId} />}
                    {activeStep === 1 && (
                      <DeviceForm
                        currentposition={currentposition}
                        value={formValues}
                        setFormValues={setFormValues}
                        setisFormValid={setisFormValid}
                        isFormValid={isFormValid}
                        editForm={false}
                        isNew={firstInteraction}
                        group={group}
                        setGroup={setGroup}
                        programId={programId}
                      />
                    )}
                    {activeStep === 2 && (
                      <Grid container item xs={12} lg={9} xl={8}>
                        {console.log("datos del equipo de confirmación", device)}
                        <Confirmation device={device} />
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12} lg={9} xl={8} container justify="flex-end" alignContent="space-around">
                    <Grid item>
                      <Button
                        disabled={activeStep === 0}
                        onClick={() => {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                          handleBack();
                        }}
                        className={classes.backButton}
                      >
                        {t("common.back")}
                      </Button>
                    </Grid>

                    <Grid item style={{ paddingRight: 32 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          formValues.model === devicesModelsBack[0] ? handleNext100() : handleNext();
                          window.scrollTo(0, 0);
                        }}
                        disabled={!isValid || isLoading}
                      >
                        {isLoading && <ButtonLoader />}
                        {isFinish ? t("common.finish") : t("common.next")}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            )}
          </div>
        </div>
      </Dialog>

      <Dialog open={openAlert}>
        <DialogTitle style={{ color: isError && red[500] }}>{isError ? t("device.new.dialog.title.error") : t("device.new.dialog.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {isError ? (
              <>
                {errorIdLimbo !== -1 && t(errorOnGetLimbo[errorIdLimbo])}
                {errorIdMove !== -1 && t(errorOnMoveLimbo[errorIdMove])}
                {errorOnCreate !== -1 && t(errorOncreateNoR100Device[errorOnCreate])}
              </>
            ) : (
              t("device.new.dialog.description")
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenAlert(false);
              !isError && resetForm();
            }}
            variant="contained"
            color="primary"
            autoFocus
          >
            {t("devices.setUp.dialog.agree")}
          </Button>
        </DialogActions>

        <Dialog open={openAlertProccess}>
          <DialogTitle id="alert-dialog-title">{t("devices.new.dialog.title")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{t("devices.new.dialog.description")}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenAlertProccess(false);
                resetForm();
              }}
              variant="contained"
              color="primary"
              autoFocus
            >
              {t("devices.new.dialog.agree")}
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog>
      <R100ConfigChangedModal open={openR100ConfingWarning} handleClose={finishR100Register} isNew />
      <ErrorDialog errorMsg={connectionErrorMsg} clearError={() => setConnectionErrorMsg("")} />
      <GroupNotCompatibleModal
        open={groupNotCompatibleModalOpen}
        setOpen={setGroupNotCompatibleModalOpen}
        cancel={closeXButton}
        deviceComs={device?.comsTypes}
        setNewGroupId={setCreatedNewGroupId}
        nextStep={handleNext100}
        programId={programId}
        user={authUser}
      />
    </>
  );
};

export default AddDevice;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const errorOncreateNoR100Device = {
  2: "device.new.dialog.description.error",
  3: "create.device.error.notFoundOrpermission",
  4: "create.device.error.duplicateSN",
};

const errorOnGetLimbo = {
  2: "device.new.dialog.description.error",
  3: "create.device.error.notFoundOrpermission",
  4: "create.device.error.duplicateSN",
  5: "device.new.dialog.description.error_in_use",
  6: "device.new.dialog.description.error_wrong_key",
  7: "device.new.dialog.description.error_wrong_device",
};

const errorOnMoveLimbo = {
  2: "device.new.dialog.description.error",
  3: "create.device.error.notFoundOrpermission",
  4: "create.device.error.duplicateSN",
  5: "create.device.error.invalidGroup_coms",
};

const devicesModelsBack = ["Reanibex 100", "Reanibex 200", "Reanibex 300", "Reanibex 500"];

const DEFAULT_GPS_POSITION = {
  lat: 43.1858435,
  lng: -2.5094801,
};
